::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #9ccc65;
  border-radius: 10px;
}

.loading-img {
  border-radius: 50%;
}
